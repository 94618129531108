import React from 'react';

import { appConfig } from './config';
import { formatHtml } from './util';

export interface IubendaPrivacyDeclarationProps {
  policyType?: 'privacy' | 'cookie';
}

export const IubendaPrivacyDeclaration = ({ policyType = 'privacy' }: IubendaPrivacyDeclarationProps) => {
  const [content, setContent] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(true);

  const urlFetch =
    policyType === 'cookie'
      ? `https://www.iubenda.com/api/privacy-policy/${appConfig.iubenda?.cookiePolicyId}/cookie-policy/no-markup`
      : `https://www.iubenda.com/api/privacy-policy/${appConfig.iubenda?.cookiePolicyId}/no-markup`;

  React.useEffect(() => {
    if (appConfig.iubenda) {
      const fetchContentIubenda = async () => {
        try {
          await fetch(urlFetch)
            .then((response) => response.json())
            .then((data) => {
              if (data.success === true) {
                console.log(data);
                setContent(data.content);
                setIsLoading(false);
              }
            });
        } catch (error) {
          console.error('Errore nel caricamento della privacy policy:', error);
          setIsLoading(false);
        }
      };
      fetchContentIubenda();
    } else {
      setIsLoading(false);
    }
  }, [urlFetch]);

  return (
    <div>
      {isLoading ? (
        <div>Caricamento privacy policy...</div>
      ) : content ? (
        <div className={`iubenda-${policyType}-declaration`}>{formatHtml(content)}</div>
      ) : (
        <p>Si è verificato un problema con Iubenda</p>
      )}
    </div>
  );
};
