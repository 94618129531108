/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React from 'react';

import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { createUUID } from './util';

export interface Zakeke3DViewerProps {
  allow?: string;
  backgroundImage?: string;
  modelCode?: string;
  name?: string;
  productId: string;
}

export const Zakeke3DViewer = ({
  allow = 'fullscreen; accelerometer; magnetometer; autoplay; encrypted-media; gyroscope; picture-in-picture; camera *; xr-spatial-tracking;',
  backgroundImage,
  modelCode,
  name,
  productId,
}: Zakeke3DViewerProps) => {
  const { locale, shippingCountry } = useAppContext();
  const title = React.useMemo(() => `future-fashion-3d-viewer-${createUUID()}`, []);
  const currency = 'EUR';

  return (
    <div
      className="product-3d-viewer w-100"
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      {/* <iframe
        allowFullScreen
        height={'500px'}
        src={`https://3dviewer.futurefashionsolution.com/?customerId=${
          appConfig.zakeke3DViewer?.customerId
        }&productId=${productId}&lang=${locale?.languageCode ?? 'en'}`}
        title={title}
        width={'100%'}
      /> */}
      <iframe
        allow={allow}
        height={'600px'}
        src={`https://www.3dandarviewer.com/?name=${name ?? productId}&modelCode=${modelCode ?? productId}&userID=${
          appConfig.zakeke3DViewer?.userId
        }&dam=true&currency=${currency}&ecommerce=viewer&qty=1&culture=${locale?.code ?? 'en'}-${
          shippingCountry?.code ?? 'US'
        }&fromUrl=1`}
        title={title}
        width={'100%'}
      />
    </div>
  );
};
