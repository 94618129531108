import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountQuickReorderSubpageA } from './accountSubpagesA/AccountQuickReorderSubpageA';

export interface AccountQuickReorderSubpageProps {}

export type AccountQuickReorderSubpageVariants = 'A';

const AccountQuickReorderSubpageController = (props: PropsWithCustomComponent<AccountQuickReorderSubpageProps>) => (
  <VariantsController<AccountQuickReorderSubpageProps, AccountQuickReorderSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountQuickReorderSubpageA },
      name: 'AccountQuickReorderSubpage',
    }}
  />
);
export { AccountQuickReorderSubpageController as AccountQuickReorderSubpage };
