import './SliderA.scss';
import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss'; // core Swiper

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BoxSectionList } from '../contentTypes';
import { SliderButton } from './SliderButton';

export interface SliderAProps {
  itemList: b2x.contentTypes.ContentSectionList<BoxSectionList>;
}

export const SliderA = ({ itemList }: SliderAProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);

  return (
    <div className="slider-a position-relative p-3 p-lg-0" id={sliderUUID}>
      <Swiper
        spaceBetween={16}
        {...(currentBreakpoint === 'xs' && { slidesPerView: 1 })}
        {...(currentBreakpoint === 'sm' && { slidesPerView: 2 })}
        {...(currentBreakpoint === 'md' && { slidesPerView: 2 })}
        {...(currentBreakpoint === 'lg' && { slidesPerView: 2 })}
        {...(currentBreakpoint === 'xl' && { slidesPerView: 3 })}
        {...(currentBreakpoint === 'xxl' && { slidesPerView: 3 })}
        modules={[Navigation]}
        navigation={{ nextEl: `#${sliderUUID} .swiper-button-next`, prevEl: `#${sliderUUID} .swiper-button-prev` }}
      >
        {itemList.map((item, index) => (
          <SwiperSlide key={item.contentSectionId}>
            {(slideData) => (
              <div className="slide">
                {item.product3dId ? (
                  <b2x.Zakeke3DViewer productId={item.product3dId} />
                ) : b2x.isVideoFromContentValid(item.video) ? (
                  <b2x.VideoFromContentV1 {...item.video} />
                ) : (
                  <div className="position-relative">
                    {item.img && <b2x.ImageFromContentV1 {...item.img} fluid />}
                    {item.copy && (
                      <div className="message">
                        <div className="text-center py-4 px-lg-3">
                          {item.copy.title && (
                            <p
                              className={classnames(
                                'fw-bold',
                                { 'h3 mb-0': b2x.untilBreakpoint('md', currentBreakpoint) },
                                { h1: !b2x.untilBreakpoint('md', currentBreakpoint) }
                              )}
                            >
                              {b2x.formatHtml(item.copy.title)}
                            </p>
                          )}
                          {item.copy.subtitle && (
                            <p
                              className={classnames(
                                { 'm-0': b2x.untilBreakpoint('md', currentBreakpoint) },
                                { 'h2 mb-4': !b2x.untilBreakpoint('md', currentBreakpoint) }
                              )}
                            >
                              {b2x.formatHtml(item.copy.subtitle)}
                            </p>
                          )}
                          {item.cta && (
                            <b2x.DeprecatedCta
                              className="fw-medium border-2 border-bottom border-primary text-decoration-none d-inline-block"
                              cta={item.cta}
                            >
                              {item.cta.label}
                            </b2x.DeprecatedCta>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="slider-navigation position-absolute top-0 start-0 w-100 h-100">
        <SliderButton direction="left" size={'small'} />
        <SliderButton direction="right" size={'small'} />
      </div>
    </div>
  );
};
