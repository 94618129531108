import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { ServicePrivacySubpageA } from './serviceSubpagesA/ServicePrivacySubpageA';

export interface ServicePrivacySubpageProps {}

export type ServicePrivacySubpageVariants = 'A';

const ServicePrivacySubpageController = (props: PropsWithCustomComponent<ServicePrivacySubpageProps>) => (
  <VariantsController<ServicePrivacySubpageProps, ServicePrivacySubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServicePrivacySubpageA },
      name: 'ServicePrivacySubpage',
    }}
  />
);
export { ServicePrivacySubpageController as ServicePrivacySubpage };
