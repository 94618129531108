import { b2x } from '@b2x/react/src';

import { AppContext } from './AppContext';
import { Footer } from './Footer';
import { Header } from './Header';
import { Icomoon } from './Icomoon';
import { ModalsAndOffcanvasses } from './ModalsAndOffcanvasses';
import { AccountHomePage } from './pages/AccountHomePage';
import { AccountPage } from './pages/AccountPage';
import { AmbassadorPage } from './pages/AmbassadorPage';
import { AmbassadorsPage } from './pages/AmbassadorsPage';
import { BuilderV1Page } from './pages/BuilderV1Page';
import { CartPage } from './pages/CartPage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { Error404Page } from './pages/Error404Page';
import { Error500Page } from './pages/Error500Page';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { GiftCardPage } from './pages/GiftCardPage';
import { HomePage } from './pages/HomePage';
import { LandingExternalPage } from './pages/LandingExternalPage';
import { LandingTemplateAPage } from './pages/LandingTemplateAPage';
import { NewsletterSubscribePage } from './pages/NewsletterSubscribePage';
import { ProductPage } from './pages/ProductPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { SearchPage } from './pages/SearchPage';
import { ServicePage } from './pages/ServicePage';
import { StoreLocatorPage } from './pages/StoreLocatorPage';
import { TemplatePage } from './pages/TemplatePage';
import { ThankYouPage } from './pages/ThankYouPage';
import { ThankYouPageOrderFailed } from './pages/ThankYouPageOrderFailed';
import { UnsubscribeNewsletterPage } from './pages/UnsubscribeNewsletterPage';
import { WhoisPage } from './pages/WhoisPage';

export const App = () => {
  return (
    <AppContext>
      {(appContext, appStaticContext) => (
        <b2x.App
          icomoonElement={<Icomoon />}
          onAddToCartSuccess={appStaticContext.showCartOffcanvas}
          onAddToWishlistAsGuestButtonClick={appStaticContext.showAccountOffcanvas}
        >
          <ModalsAndOffcanvasses />
          <div className="d-flex flex-column flex-grow-1">
            <Header />
            <div className="flex-grow-1">
              <b2x.router.MainRouter
                codeMappings={{
                  SITEMAP: <HomePage />,
                  SITE_ACCOUNT: <AccountHomePage />,
                  SITE_AMBASSADOR: <AmbassadorsPage />,
                  SITE_CCN_AREA: { component: <AccountPage />, separateRouter: true },
                  SITE_CUSTOMER_CARE: { component: <ServicePage />, separateRouter: true },
                  SITE_LEGAL_AREA: { component: <ServicePage />, separateRouter: true },
                  SITE_NEWSLETTER: <NewsletterSubscribePage />,
                  SITE_STORE_LOCATOR: { component: <StoreLocatorPage />, dynamicSegment: true },

                  SITE_TEMPLATE: <TemplatePage />,
                }}
                error404Page={<Error404Page />}
                fixedMappings={{
                  cart: <CartPage />,
                  'change-password': <ChangePasswordPage />,
                  checkout: <CheckoutPage />,
                  error: <Error500Page />,
                  'forgot-password': <ForgotPasswordPage />,
                  'order-ko': <ThankYouPageOrderFailed />,
                  'order-ok': <ThankYouPage />,
                  register: <RegistrationPage />,
                  search: <SearchPage />,
                  'unsubscribe-newsletter': <UnsubscribeNewsletterPage />,
                  whois: <WhoisPage />,
                }}
                templateMappings={{
                  AMBASSADOR_PAGE_CONTENT_TYPE: <AmbassadorPage />,
                  LANDING_EXTERNAL_PAGE_CONTENT_TYPE: <LandingExternalPage />,
                  LANDING_TEMPLATE_A_PAGE_CONTENT_TYPE: <LandingTemplateAPage />,
                  PAGE_BUILDER_V1_CONTENT_TYPE: <BuilderV1Page />,
                }}
                typeMappings={{
                  CATALOG: <SearchPage />,
                  LISTING: <SearchPage />,
                  PRODUCT: <b2x.ProductTypeSwitch default={<ProductPage />} giftCard={<GiftCardPage />} />,
                }}
              />
            </div>
            {!appContext.hiddenFooter && <Footer />}
            <div id="products-tiles-container"></div>
          </div>
          <b2x.FloatingActionButtonContainer direction="column" position="bottom start">
            <b2x.CookieFABButton />
          </b2x.FloatingActionButtonContainer>
        </b2x.App>
      )}
    </AppContext>
  );
};
