import 'swiper/css/bundle';
import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Accordion } from '../Accordion';
import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import { MiscellaneousContentType } from '../contentTypes';
import { CustomRadio } from '../CustomRadio';
import { t } from '../i18n/i18n';
import { Icon, IconName, IconSize } from '../Icon';
import { Section } from '../Section';
import { SkuAttributeFormGroup } from '../SkuAttributeFormGroup';
import { Slider } from '../slider/Slider';
import { SliderProduct } from '../slider/SliderProduct';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });
  const { isFooterCopyrightVisible, isTopBarRowVisible } = useAppContext();

  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  const [taglieRef, scrollToTaglieRef] = b2x.useScrollTo();

  const dataSheet = product?.attributes?.find((attribute) => attribute.typeCode === 'EQS_SCHEDATECNICA')?.value;

  const breakpoint = b2x.useBreakpoint();

  const zakeke3dViewerProductId = product?.attributes?.find((attribute) => attribute.typeCode === 'EQS_3DVIDEO')?.value;

  return (
    <Page className="product-page" noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <>
              <b2x.AddToCartFormHelper product={product} scope="product">
                {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                  <>
                    <Section className="pb-md-5 mb-5">
                      <Container className="g-0" fluid="always">
                        <b2x.Row className="g-0">
                          <b2x.Col size={{ lg: 7, md: 6, xs: 12 }}>
                            <ProductGallery
                              product={product}
                              selectedProductVariant={selectedProductVariant}
                              selectedSku={selectedSku}
                            />
                          </b2x.Col>
                          <b2x.Col size={{ lg: 5, md: 6, xs: 12 }}>
                            <div className="py-md-5 px-md-3 px-xl-5">
                              <div className="product-main-info px-3 px-md-0">
                                <Breadcrumb hideLastItem />
                                <div className="mb-3 d-flex justify-content-between">
                                  {selectedProductVariant.name && (
                                    <div className="product-name pe-3">
                                      <h1 className="m-0">{b2x.formatHtml(selectedProductVariant.name)}</h1>
                                    </div>
                                  )}
                                  <div className="product-wishlist pt-2">
                                    <b2x.WishlistButtonHelper product={product} sku={selectedSku}>
                                      {({ handleWishlistButtonClick, inWishlist }) => (
                                        <Button
                                          className=""
                                          iconEnd={{
                                            name: inWishlist ? 'wishlist-full' : 'wishlist',
                                            size: b2x.untilBreakpoint('sm', breakpoint) ? 25 : 35,
                                          }}
                                          onClick={handleWishlistButtonClick}
                                          variant="blank"
                                        />
                                      )}
                                    </b2x.WishlistButtonHelper>
                                  </div>
                                </div>
                                {selectedProductVariant.code && (
                                  <p className="product-code extra-small mb-4">
                                    {t('product.code')}: {selectedProductVariant.code}
                                  </p>
                                )}
                                {selectedProductVariant.descriptionShort && (
                                  <div className="product-description-short mb-4">
                                    {b2x.formatHtml(selectedProductVariant.descriptionShort)}
                                  </div>
                                )}
                                <div className="product-price-container mb-4">
                                  <b2x.PriceBlock
                                    classNames={{ price: 'fw-bold h2 mb-0', specialPrice: 'h2 mb-0' }}
                                    gap={3}
                                    priceHelper={priceHelper}
                                  />
                                  {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
                                    <div className="small">
                                      <b2x.BestPrice priceHelper={priceHelper} />
                                    </div>
                                  )}
                                </div>
                                <div className="product-options">
                                  {fieldsHelper.productVariants.formFields.length > 1 && (
                                    <b2x.FormGroup {...fieldsHelper.productVariants.formGroup} label={undefined}>
                                      {selectedProductVariant.attributes &&
                                        selectedProductVariant.attributes.map(
                                          (attribute) =>
                                            (attribute.typeCode === 'EQS_COLOR' ||
                                              attribute.typeCode === 'EQS_ECO-WOOL_COLOR' ||
                                              attribute.typeCode === 'EQS_FABRIC_COLOR' ||
                                              attribute.typeCode === 'EQS_GEL_COLOR' ||
                                              attribute.typeCode === 'EQS_CRYSTAL_COLOR' ||
                                              attribute.typeCode === 'EQS_DRI-LEX_COLOR' ||
                                              attribute.typeCode === 'EQS_GRIP_COLOR' ||
                                              attribute.typeCode === 'EQS_MICROPILE_COLOR' ||
                                              attribute.typeCode === 'EQS_SHEEPSKIN_COLOR') && (
                                              <div className="form-label small fw-light mb-0" key={attribute.typeCode}>
                                                <span className="fw-normal">
                                                  {attribute.title}: {attribute.value}
                                                </span>
                                              </div>
                                            )
                                        )}
                                      <div className="variant-slider-container position-relative mt-3">
                                        <Slider
                                          navigation
                                          slideContentCentered
                                          slidesPerView={'auto'}
                                          spaceBetween={12}
                                        >
                                          {fieldsHelper.productVariants.formFields.map((formField) => (
                                            <SwiperSlide key={formField.productVariant.id}>
                                              <b2x.Radio {...formField.radio} inline noMargin>
                                                <CustomRadio
                                                  imageUrl={formField.productVariant.image?.src}
                                                  productName={`${formField.productVariant.name} - radio`}
                                                />
                                              </b2x.Radio>
                                            </SwiperSlide>
                                          ))}
                                        </Slider>
                                      </div>
                                    </b2x.FormGroup>
                                  )}
                                  {fieldsHelper.skuAttributes.map(({ attribute, formGroup, radios }, index) => (
                                    <div key={attribute.title} ref={taglieRef}>
                                      <SkuAttributeFormGroup
                                        attribute={attribute}
                                        className="position-relative size-select-container"
                                        formGroup={formGroup}
                                        radios={radios}
                                        sliderResponsive={{}}
                                        slidesPerView={'auto'}
                                        spaceBetween={12}
                                      />
                                    </div>
                                  ))}
                                  <div className="d-grid mt-3 mt-md-2">
                                    {/* <Button
                                      {...fieldsHelper.buttons.submit}
                                      variant="primary"
                                    /> */}
                                    <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                      fieldsHelper={fieldsHelper}
                                      selectedSku={selectedSku}
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <b2x.InstallmentPaymentBanner amount={priceHelper.price} />
                                  </div>
                                  {miscellaneousContent?.body.productDetails?.shippingLabel && (
                                    <div className="d-flex justify-content-center py-3 text-uppercase fw-medium lh-sm">
                                      <Icon className="me-2" name="delivery" size={25} />
                                      <span>{miscellaneousContent.body.productDetails.shippingLabel}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="product-secondary-info">
                                <ProductAccordion product={product} />
                              </div>
                            </div>
                          </b2x.Col>
                        </b2x.Row>
                        <b2x.Portal>
                          <b2x.ProductStickyFooter
                            fieldsHelper={fieldsHelper}
                            formik={formik}
                            isVisible={!isTopBarRowVisible && !isFooterCopyrightVisible}
                            priceHelper={priceHelper}
                            product={product}
                            scrollToElement={scrollToTaglieRef}
                            selectedProductImage={selectedProductVariant.image}
                            selectedSku={selectedSku}
                          />
                        </b2x.Portal>
                      </Container>
                    </Section>
                    {dataSheet && (
                      <Section className="pb-md-5 mb-5 data-sheet" container="xxl">
                        <b2x.Row>
                          <b2x.Col className="d-flex align-items-center order-md-1 order-3" size={{ md: 6, sm: 12 }}>
                            <div>
                              <h5 className="d-none d-md-block h1 fw-bold mb-5">{t('product.technicalFeatures')}</h5>
                              <div className={classnames({ h5: !b2x.untilBreakpoint('sm', breakpoint) })}>
                                {b2x.formatHtml(dataSheet)}
                              </div>
                              <div>
                                {product.attributes?.find(
                                  ({ typeCode }) => typeCode && typeCode.includes('EQS_ICONATECNICA')
                                ) && (
                                  <div className="d-flex flex-wrap gap-3 gap-xl-4 justify-content-start">
                                    {product.attributes.map(
                                      (attribute) =>
                                        attribute.typeCode &&
                                        attribute.typeCode.includes('EQS_ICONATECNICA') && (
                                          <div
                                            className="d-flex flex-column gap-2 align-items-center"
                                            key={attribute.typeCode}
                                          >
                                            <div className="product-technical-icon">
                                              <b2x.Image height={72} src={attribute.value} width={72} />
                                            </div>
                                          </div>
                                        )
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </b2x.Col>
                          <b2x.Col className="order-2" size={{ md: 6, sm: 12 }}>
                            <h5 className="d-md-none h1 fw-bold text-center">{t('product.technicalFeatures')}</h5>
                            <div className="d-flex align-items-center justify-content-center mb-3 mb-md-0">
                              {
                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                zakeke3dViewerProductId ? (
                                  <b2x.Zakeke3DViewer productId={zakeke3dViewerProductId} />
                                ) : (
                                  selectedProductVariant.image && (
                                    <b2x.Image
                                      {...selectedProductVariant.image}
                                      alt={selectedProductVariant.name}
                                      fluid
                                      style={{ maxWidth: '350px' }}
                                    />
                                  )
                                )
                              }
                            </div>
                          </b2x.Col>
                        </b2x.Row>
                      </Section>
                    )}
                  </>
                )}
              </b2x.AddToCartFormHelper>
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts &&
                    relatedProducts.length > 0 && <RelatedProducts relatedProducts={relatedProducts} />
                  )
                }
              </b2x.RelatedProducts>
            </>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductGalleryProps {
  product: b2x.ProductApiDto;
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductGallery = ({ product, selectedProductVariant, selectedSku }: ProductGalleryProps) => {
  const allSlides = b2x.useGallery(selectedProductVariant, selectedSku).slides;
  //const partialSlides = allSlides.slice(0, 4);

  // const breakpoint = b2x.useBreakpoint();

  // const [showAllSlides, setShowAllSlides] = React.useState<boolean>(false);

  // const onShowAllSlidesButtonClick = React.useCallback(() => {
  //   setShowAllSlides(true);
  // }, []);

  // const productGallery = showAllSlides || b2x.untilBreakpoint('sm', breakpoint) ? allSlides : partialSlides;

  const zakeke3dViewerMain = selectedProductVariant.attributes?.find(
    (attribute) => attribute.typeCode === 'EQS_3DVIDEOMAIN'
  )?.value;
  const zakeke3dViewerProductId =
    zakeke3dViewerMain === 'true' &&
    selectedProductVariant.attributes?.find((attribute) => attribute.typeCode === 'EQS_3DVIDEO')?.value;

  const productGallery = allSlides;

  return (
    <div className="product-gallery py-1 px-1">
      <b2x.EqualHeight>
        <div className="product-gallery-container">
          <b2x.Lightbox>
            <Swiper modules={[Navigation]} navigation slidesPerView={1} spaceBetween={0} watchSlidesProgress>
              {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                zakeke3dViewerProductId && (
                  <SwiperSlide>
                    <div className="product-slide-image-container h-100 w-100 d-flex align-items-center p-1">
                      <div className="border border-gray-200 h-100 w-100">
                        <b2x.EqualHeightElement name="media-slide">
                          <b2x.Zakeke3DViewer productId={zakeke3dViewerProductId} />
                        </b2x.EqualHeightElement>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              }
              {productGallery.map((slide, index) => (
                <SwiperSlide
                  className="text-center"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`product-slide-${index}`}
                >
                  {(slideData) => (
                    <div className="product-slide-image-container d-flex align-items-center justify-content-center p-1">
                      <div className="border border-gray-200">
                        <b2x.EqualHeightElement name="media-slide">
                          {slide.type === 'image' && slide.src ? (
                            <b2x.LightboxItem
                              bcomImageId={slide.uId}
                              className="d-block bg-gray-100 w-100"
                              src={slide.src}
                            >
                              <b2x.Image
                                alt={product.name}
                                aspectRatio={b2x.appConfig.productImageAspectRatio}
                                fluid
                                src={slide.src}
                              />
                            </b2x.LightboxItem>
                          ) : (
                            slide.type === 'video' && (
                              <b2x.DeprecatedVideoFromContent
                                autoplay
                                controls
                                src={{ xs: { preview: { url: slide.thumbnail }, video: slide.src } }}
                              />
                            )
                          )}
                        </b2x.EqualHeightElement>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </b2x.Lightbox>
        </div>
        {/* {!b2x.untilBreakpoint('sm', breakpoint) && !showAllSlides && allSlides.length > 4 && (
        <div className="d-flex pt-5 justify-content-center">
          <Button
            className="fw-medium"
            label={t('product.showAllImages')}
            onClick={onShowAllSlidesButtonClick}
            variant="outline-secondary"
          />
        </div>
      )} */}
      </b2x.EqualHeight>
    </div>
  );
};

interface ProductAccordionProps {
  product: b2x.ProductApiDto;
}

const ProductAccordion = ({ product }: ProductAccordionProps) => {
  return (
    <Accordion
      className="product-accordion"
      id="product-accordion"
      itemBodyClassName="pt-0"
      itemButtonClassName="bg-transparent"
      itemClassName="border-black"
    >
      <b2x.AccordionItem id="product-description" show title={t('product.description')}>
        {product.description && b2x.formatHtml(product.description)}
      </b2x.AccordionItem>
      {product.attributes
        ?.filter(
          (attribute) =>
            attribute.typeCode && ['EQS_SIZEFIT', 'EQS_INFOCARE', 'EQS_COMPOSIZIONE'].includes(attribute.typeCode)
        )
        .map(
          (attribute) =>
            attribute.typeCode && (
              <b2x.AccordionItem id={attribute.typeCode} key={attribute.typeCode} title={attribute.title ?? ''}>
                {b2x.formatHtml(attribute.value)}
              </b2x.AccordionItem>
            )
        )}
    </Accordion>
  );
};

interface RelatedProductsProps {
  relatedProducts: Array<b2x.ProductApiDto>;
}

const RelatedProducts = ({ relatedProducts }: RelatedProductsProps) => {
  const breakpoint = b2x.useBreakpoint();
  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  return (
    <Section className="related-products mb-5">
      <Container>
        <div className="text-center">
          {miscellaneousContent?.body.productDetails?.related?.header?.title && (
            <h3 className={classnames('fw-bold mb-5', { h1: !b2x.untilBreakpoint('sm', breakpoint) })}>
              {miscellaneousContent.body.productDetails.related.header.title}
            </h3>
          )}
        </div>
        <div className="border-gray-300 border-bottom pb-5 mb-5">
          <b2x.Listing name="Product page - Related products" products={relatedProducts}>
            <SliderProduct products={relatedProducts} />
          </b2x.Listing>
        </div>
        <div className="text-center">
          {miscellaneousContent?.body.productDetails?.related?.footer?.title && (
            <h3 className={classnames('fw-bold mb-5', { h1: !b2x.untilBreakpoint('sm', breakpoint) })}>
              {miscellaneousContent.body.productDetails.related.footer.title}
            </h3>
          )}
          {miscellaneousContent?.body.productDetails?.related?.footer?.cta && (
            <b2x.CtaFromContent
              ctaProps={{
                button: { className: 'fw-medium btn-outline-secondary' },
              }}
              {...miscellaneousContent.body.productDetails.related.footer.cta}
            />
          )}
        </div>
      </Container>
    </Section>
  );
};
