import React from 'react';

import { t } from './i18n/i18n';
import { percentageOf } from './util';

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  colors?: {
    background?: string;
    progress?: string;
  };
  current: number;
  height?: number;
  hiddenCount?: boolean;
  max: number;
  min: number;
}

export const ProgressBar = ({ colors, current, height = 10, hiddenCount, max, min }: ProgressBarProps) => {
  const currentValue = percentageOf(current, max, 2);

  return (
    <div className="progress" style={{ background: colors?.background, height: height, position: 'relative' }}>
      <div
        aria-label={t('accessibility.progressbar', { currentValue: currentValue })}
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={currentValue}
        className="progress-bar"
        role="progressbar"
        style={{
          background: colors?.progress,
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: `${currentValue}%`,
        }}
      >
        {!hiddenCount && <small>{currentValue}</small>}
      </div>
    </div>
  );
};
