import { appConfig } from '../../../config';
import { ServiceSubpageContentType } from '../../../contentTypes';
import { IubendaPrivacyDeclaration } from '../../../IubendaPrivacyDeclaration';
import { formatHtml } from '../../../util';
import { ServiceSubpage } from '../ServiceSubpage';

export interface ServicePrivacySubpageAProps {}

export const ServicePrivacySubpageA = (props: ServicePrivacySubpageAProps) => {
  return (
    <ServiceSubpage<ServiceSubpageContentType>>
      {(page) => (
        <>
          <div>{formatHtml(page.content?.body.content)}</div>

          {appConfig.iubenda && <IubendaPrivacyDeclaration policyType="privacy" />}
        </>
      )}
    </ServiceSubpage>
  );
};
